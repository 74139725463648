import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { type EnumsUserSettingsGroupKey } from '@lp-lib/api-service-client/public';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

type FormModifiedProps = {
  groupKey: EnumsUserSettingsGroupKey;
  field: string;
  value: unknown;
};

export interface UserSettingsAnalytics {
  trackUserSettingsPanelViewed(panelName: string): void;
  trackUserSettingsModified(props: FormModifiedProps): void;
  trackUserSettingsSaved(groupKey: EnumsUserSettingsGroupKey): void;
}

class UserSettingsAnalyticsImpl implements UserSettingsAnalytics {
  constructor(
    readonly analytics: AnalyticsBrowser,
    readonly orgId: string | undefined
  ) {}

  trackUserSettingsPanelViewed(panelName: string): void {
    this.analytics.track(
      'User Settings Panel Viewed',
      this.buildEventProps({
        panelName,
      })
    );
  }

  trackUserSettingsModified(props: FormModifiedProps): void {
    this.analytics.track('User Settings Modified', this.buildEventProps(props));
  }

  trackUserSettingsSaved(groupKey: EnumsUserSettingsGroupKey): void {
    this.analytics.track(
      'User Settings Saved',
      this.buildEventProps({ groupKey })
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useUserSettingsAnalytics(): UserSettingsAnalytics {
  const orgId = useMyOrgId();
  const analytics = useAnalytics();
  return useMemo(
    () => new UserSettingsAnalyticsImpl(analytics, orgId ?? undefined),
    [analytics, orgId]
  );
}
